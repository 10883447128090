// vue
import { ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// types
import type { OrganizationInfo } from '@revolutionprep/types'

export const useOrganizationStore = defineStore('organization', () => {
  // state
  const organizationInfo = ref<OrganizationInfo>({
    name: 'Revolution Prep',
    tagLine: 'Learn Smarter',
    address: {
      street1: '1337 3rd Street Promenade',
      street2: '3rd Floor',
      city: 'Santa Monica',
      state: 'CA',
      zip: '90401',
      country: 'United States'
    },
    phone: '+18777387737',
    department: {
      id: 1,
      name: 'Retail',
      handle: 'retail'
    }
  })

  return { organizationInfo }
})
